@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'sansTh';
  src: url('./fonts/IBMPlexSansThai-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sansTh';
  src: url('./fonts/IBMPlexSansThai-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'sansTh';
  src: url('./fonts/IBMPlexSansThai-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'sansTh';
  src: url('./fonts/IBMPlexSansThai-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'sansTh';
  src: url('./fonts/IBMPlexSansThai-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'sansTh';
  src: url('./fonts/IBMPlexSansThai-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'sansTh';
  src: url('./fonts/IBMPlexSansThai-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

:root {
  font-family: 'sansTh';
}
